<template>
  <div id="home">
    <Myheader></Myheader>
    <MySidebar></MySidebar>

    <div class="content-box" :class="{shouContant:collapse}">
      <v-tags></v-tags>
      <div class="content">
        <transition name="move" mode="out-in">
          <router-view></router-view>
        </transition>
        <el-backtop target=".content"></el-backtop>
      </div>
    </div>
  </div>
</template>

<script>
  import Myheader from "../../components/common/Myheader";
  import MySidebar from "../../components/common/Sidebar";
  import vTags from "../../components/common/Tags";
  import bus from '../../components/common/bus'
  // import Mywelcome from "../../components/WelcomeHome/welcome";
  export default {
    data() {
      return {
        collapse: false,
      };
    },
    components: {
      Myheader,
      vTags,
      MySidebar
      // Mywelcome
    },
    created() {
      bus.$on("collapse-content", msg => {
        this.collapse = msg;
      });

      // 只有在标签页列表里的页面才使用keep-alive，即关闭标签之后就不保存到内存中了。
      bus.$on("tags", msg => {
        let arr = [];
        for (let i = 0, len = msg.length; i < len; i++) {
          msg[i].name && arr.push(msg[i].name);
        }
        this.tagsList = arr;
      });
    }
  };
</script>

<style lang="less" scoped>
  .el-backtop {
    bottom: 5px !important;
  }
  /deep/.el-icon-caret-top:before {
    content: "TOP";
    font-size: 1px;
    color: black;
  }
  .content-box {
    left: 205px;
  }
  .shouContant{
    /*width:calc(100vm  -142px) !important;*/
    margin: 0px 1% 0px -6%;
  }
</style>