<template>
  <div class="sidebar">
    <el-menu
      class="sidebar-el-menu"
      :default-active="onRoutes"
      :collapse="collapse"
      background-color="#324157"
      text-color="#bfcbd9"
      active-text-color="#20a0ff"
      unique-opened
      router
    >
      <template v-for="item in items">
        <template v-if="item.subs">
          <el-submenu :index="item.index" :key="item.index">
            <template slot="title">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.subs">
              <el-submenu v-if="subItem.subs" :index="subItem.index" :key="subItem.index">
                <template slot="title">{{ subItem.title }}</template>
                <el-menu-item
                  v-for="(threeItem,i) in subItem.subs"
                  :key="i"
                  :index="threeItem.index"
                >{{ threeItem.title }}</el-menu-item>
              </el-submenu>
              <el-menu-item v-else :index="subItem.index" :key="subItem.index">{{ subItem.title }}</el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import bus from "../common/bus";
export default {
  data() {
    return {
      collapse: false,
      items: [
        {
          icon: "el-icon-s-home",
          index: "Welcome",
          title: "系统首页",
        },

        // 系统设置
        {
          icon: "el-icon-user-solid",
          index: "1",
          title: "系统设置",
          subs: [
            {
              index: "Announcement",
              title: "公告管理",
            },
            {
              index: "Give",
              title: "赠送设置",
            },
            {
              index: "GlobleDeploy",
              title: "全局配置",
            },
            {
              index: "SalaList",
              title: "大厅列表",
            },
            {
              index: "LoginConfig",
              title: "登录列表",
            },
            {
              index: "GateConfig",
              title: "网关列表",
            },
          ],
        },
        {
          icon: "el-icon-mouse",
          index: "2",
          title: "游戏配置与调控",
          subs: [
            {
              index: "Gameclass",
              title: "游戏类目",
            },
            {
              index: "GameList",
              title: "游戏列表",
            },
            {
              index: "GameConfig",
              title: "游戏配置",
            },
            {
              index: "GameConfiglog",
              title: "游戏配置日志",
            },
            // {
            //   index: "Suit",
            //   title: "渠道服管理",
            // },
            // {
            //   index: "SuitLog",
            //   title: "渠道服管理日志",
            // },
          ],
        },
        {
          icon: "el-icon-user-solid",
          index: "3",
          title: "风控系统",
          subs: [
            {
              index: "Configuration",
              title: "平台风控配置",
            },
            {
              index: "Analysis",
              title: "风控数据分析",
            },
            {
              index: "Analysislog",
              title: "风控日志",
            },
            {
              index: "Riskcontrol",
              title: "风控管理",
            },
            {
              index: "Riskcontrollog",
              title: "风控管理日志",
            },
          ],
        },
      ],
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace("/", "");
    },
  },
  // created() {
  //   // 通过 Event Bus 进行组件间通信，来折叠侧边栏
  //   bus.$on("collapse", msg => {
  //     this.collapse = msg;
  //     bus.$emit("collapse-content", msg);
  //   });
  // }


};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}
.sidebar > ul {
  height: 100%;
}
.sidebar-el-menu[data-v-68d67528]:not(.el-menu--collapse) {
  width: 205px;
}
.el-submenu .el-menu-item {
  padding-left: 60px !important;
}
</style>

<style>
  .sidebar-el-menu {
    width: 205px !important;
  }
</style>